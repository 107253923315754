var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-wallet", {
    attrs: {
      "total-left-col-items": 1,
      "has-draggable": "",
      "total-right-col-items": _vm.totalRightColumns,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "leftColItem1",
          fn: function () {
            return [
              _c("module-swap", {
                attrs: {
                  "is-available": _vm.hasSwap,
                  "from-token": _vm.fromToken,
                  "to-token": _vm.toToken,
                  amount: _vm.amount,
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "rightColItem1",
          fn: function () {
            return [
              _c("module-tokens-value", {
                attrs: { draggable: _vm.hasHistory },
              }),
            ]
          },
          proxy: true,
        },
        _vm.hasHistory && _vm.hasSwap
          ? {
              key: "rightColItem2",
              fn: function () {
                return [
                  _c("module-transfer-history", {
                    attrs: { draggable: "", "is-swap": true },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }